import { createTheme } from '@material-ui/core';
import palette, {paletteDark, UpdatedPalette} from './palette';
import typography from './typography';
import overrides from './overrides';
import {BackgroundMode, BackgroundTextSupportColor} from '../components/Modules/BackgroundMode';
import { minWidth } from '@material-ui/system';

const borderRadius = 12;
const maxWidthDialog = 600;
const pageDefaultPadding = 20;

function modifiedStyles(palette) {
    return({
        palette,
        typography,
        overrides: overrides(palette),
        borderRadius: borderRadius,
        maxWidthDialog: maxWidthDialog,
        pageDefaultPadding: pageDefaultPadding,
        zIndex: {
            AppBarDesktop: 5,
            AppBarMobile: 10,
            DrawerDesktop: 8,
            DrawerStatusOverview: 3
        },
        rootFrame: {
            minHeight:'100vh',
            //background: 'radial-gradient(circle, '+palette.themeAltLightGrey+'77 20%, '+palette.backTertiary+' 100%)',
            //background-color: #eebbee;
            //backgroundImage: 'linear-gradient(230deg, '+palette.backTertiary+' 8%, '+palette.themeAltBlue+'77 33%, '+palette.backTertiary+' 66%, '+palette.themeAltLightGrey+'77 100%)'
            ///// Version 2.0.0
            backgroundImage: 'linear-gradient(270deg, '+palette.backTertiary+' 0%, '+palette.themeAltBlue+'00 33%, '+palette.backTertiary+'00 66%, '+palette.backTertiary+' 100%)'
            //paddingTop: 'calc( 0px + env(safe-area-inset-top))',
            //paddingBottom: 'calc( 0px + env(safe-area-inset-bottom))',
        },
        //// DRAWER and Navigation
        AppBarDesktop: {
            position: 'fixed',
            //position: 'static',
            //backgroundColor: palette.themeDarkBlue,
            //backgroundColor: palette.secondary.dark,
            //backgroundColor: palette.themeNaviGrey,
            backgroundColor: palette.backTertiary,
            color: palette.white,
            boxShadow: 1,
            //_boxShadow: 1,
        },
        AppBarDesktopCoach: {
            position: 'fixed',
            //position: 'static',
            //backgroundColor: palette.themeDarkBlue,
            //backgroundColor: palette.secondary.dark,
            //backgroundColor: palette.themeNaviGrey,
            backgroundColor: palette.backTertiary,
            color: palette.white,
            boxShadow: 1,
            //_boxShadow: 1,
        },
        AppBarMobile: {
            //boxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            //WebkitBoxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            boxShadow: '0px -1px 1px 1px '+palette.backPrimary,
            WebkitBoxShadow: '0px -1px 1px 1px '+palette.backPrimary,
            position: 'fixed',
            //position: 'static',
            justifyContent: "right",
            //opacity: '0.94',
            //backgroundColor: palette.secondary.dark,
            backgroundColor: palette.backTertiary,
            borderRadius: ''+borderRadius+'px '+borderRadius+'px 0 0',
            top: 'auto',
            maxHeight: 75,
            minHeight: 55,
            //paddingBottom: 0,
            //// iOS
            paddingBottom: 'calc(5px + env(safe-area-inset-bottom) + env(safe-area-inset-bottom))', /// ios
            bottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            margin: 0,
        },
        AppBarIcons: {
            //color: palette.primary.contrastText,
            color: palette.secondary.main,
            //paddingTop: 0,
            //paddingBottom: 0,
            //textAlign: 'center',
            padding: 0,
            margin: 0,
            fontSize: 25,
        },
        AppBarText: {
            color: palette.secondary.contrastText,
            fontSize: 10,
            paddingTop: 0,
            paddingBottom: 0,
            flexDirection: 'column',
            textAlign: "center",
        },
        DrawerDesktop: {
            position: 'relative',
            backgroundColor: palette.secondary.dark,
            //backgroundColor: palette.themeDarkBlue,
            boxShadow: 1,
            variant: "permanent",
            anchor: "left",
        },
        DrawerStatusOverview: {
            marginTop: 100,
            maxWidth: 300,
            border: 0,
        },
        DrawerItem: {
            //color: palette.secondary.contrastText,
            flexDirection: 'column',
            textAlign: "center",
        },
        DrawerItemAlignment: {
            flexDirection: 'column',
            textAlign: "center",
        },
        NaviItem: {
            color: palette.secondary.main,
            textDecoration: 'none', 
            fontSize:'smaller', 
            textAlign:'center',
            margin: 10
        },
        MobileStepperDesktop: {
            //borderRadius: borderRadius,
            //marginLeft: -20,
            //marginRight: -20,
            //marginBottom: -10,
            //width: 'calc(100% + 40px)',
            maxWidth: maxWidthDialog,
            flexGrow: 1,
            textAlign: "center",
            //backgroundColor: palette.secondary.main,
            //backgroundColor: palette.secondary.light,
        },
        MobileStepperAppBarMobile: {
            boxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            WebkitBoxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            //width: 'inherit',
            //maxWidth: 'inherit',
            width: '100%',
            maxWidth: maxWidthDialog,
            flexGrow: 1,
            zIndex: 100,
            position: 'fixed',
            backgroundColor: palette.secondary.main,
            borderRadius: borderRadius+'px '+borderRadius+'px 0 0',
            top: 'auto',
            maxHeight: 65,
            minHeight: 55,
            //paddingBottom: 0,
            //// iOS
            paddingBottom: 'calc(5px + env(safe-area-inset-bottom) + env(safe-area-inset-bottom))', /// ios
            bottom: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            margin: 0,
        },
        MobileAppBarRoutine: {
            color: palette.white,
            boxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            WebkitBoxShadow: '-1px -3px 6px 0px rgba(31,31,31,0.49)',
            width: '100%',
            //maxWidth: maxWidthDialog,
            maxWidth: 'inherit',
            flexGrow: 1,
            zIndex: 100,
            position: 'fixed',
            //backgroundColor: palette.secondary.light,
            backgroundColor: palette.backTertiary,
            borderRadius: '12px 12px 0 0',
            top: 'auto',
            minHeight: 55,
            //// iOS
            paddingBottom: 'calc(5px + env(safe-area-inset-bottom))', /// ios
            bottom: 0,
            paddingLeft: 10,
            paddingRight: 10,
            paddingTop: 10,
            margin: 0,
        },
        
        /////// BUTTONS
        buttonBottom: {
            float: "right",
            paddingTop: '0.679347826vh',
            paddingBottom: '0.679347826vh',
            paddingRight: '2.1739130434vh',
            paddingLeft: '2.1739130434vh',
            fontSize: '2.0380434vh',
            whiteSpace: 'nowrap'
            // borderRadius: 41, //use theme
        },
        AppFabButton: {
            position: 'absolute',
            zIndex: 1,
            top: -30,
            left: 0,
            right: 0,
            margin: '0 auto',
        },
        buttonRight: {
            float: "right",
            maxHeight: 34,
            marginBottom: 4,
            //paddingTop: 8,
            //paddingBottom: 8,
            //paddingRight: 30,
            //paddingLeft: 30
        },
        buttonCenter: {
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-around",
            alignItems: "center",
            marginBottom: 15,
        },
    
    
        /////// CARDS
        CardAppMain: {
            overflowY: 'auto', // Enable vertical scroll
            overflowX: 'hidden', 
            position: 'relative',                    // New stacking context
            touchAction: 'pan-y',
            boxShadow: 'none',
            border: '0px',
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 50,
            marginBottom: 0,
            marginLeft:0,
            marginRight:0,
            minHeight: '100%',
            //backgroundColor: 'transparent',
            backgroundColor: palette.backPrimary
            //backgroundColor: 'red'
        },
        CardDesktopMain: {
            overflowY: 'auto', // Enable vertical scroll
            overflowX: 'hidden', 
            position: 'relative',                    // New stacking context
            touchAction: 'pan-y',
            marginLeft: 'auto',
            marginRight: 20,
            maxWidth: 'calc(100vw - 300px)',
            backgroundColor: palette.backPrimary,
            borderRadius: borderRadius,
            boxShadow: 'none',
            border: '0px',
        },
        CardDesktopLeft: {
            width: '100%',
            position:"fixed",
            backgroundColor: 'transparent',
            margin: 0,
            height: "100%",
            marginTop: -80,
            boxShadow: 'none',
            border: '0px',
        },
        CardNaviLeft: {
            width: '100%',
            //width: "16vw",
            maxWidth: 240,
            height: '100%',
            paddingTop: 80,
            //paddingTop: 20,
            backgroundColor: palette.primary.default,
            paddingLeft: 20,
            paddingRight: 20,
            paddingBottom: 34,
            borderRadius: borderRadius,
            boxShadow: 'none',
            border: '0px',
        },
        CardDrawerRight: {
            backgroundColor: palette.primary.default,
            marginLeft: 30,
            marginRight: 30,
            marginTop: 100,
            height: "100%",
            boxShadow: 'none',
            border: '0px',
            maxWidth: 450
        },
        CardMobileMain: {
            backgroundColor: palette.backPaper,
            boxShadow: 'none',
            border: '0px',
        },
        CardNeutral: {
            overflow: 'unset',
            backgroundColor: 'transparent',
            boxShadow: 'none',
            border: '0px',
        },
        CardPlanBorder: {
            boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            border: '1px',
            borderColor: palette.primary.dark,
            borderRadius: "10px",
            marginBottom: 50,
            minWidth: 100,
            backgroundColor: palette.primary.dark,
            color: palette.black,
            top: 0,
            textAlign: "center",
            padding: 15
        },
        CardTopCompactMobileNavi: {
            //position: 'sticky',
            //top: 'calc(0px - env(safe-area-inset-top))',
            //maxWidth: 'inherit',
            position: 'fixed',
            top: 0,
            maxWidth: maxWidthDialog,
            width: '100%',
            zIndex: 100 ,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            opacity: '0.94',
            boxShadow: 'none',
            minHeight: 55,
            border: '0px',
            borderRadius: '0 0 '+borderRadius+'px '+borderRadius+'px',
            //paddingTop: 0,
            paddingTop: 'calc(10px + env(safe-area-inset-top))', //ios
            paddingBottom: 5,
            //paddingBottom: 0,
            marginBottom: pageDefaultPadding,
            marginTop:0,
            //background : 'linear-gradient(to top, '+palette.themeNaviGrey+' 20%, '+palette.backPaper+' 100%)',
            backgroundColor : palette.backPrimary,
            //backgroundColor: palette.primary.main,
            //opacity: "0.90",
            alignItems: "center",
            justifyContent: "center",
        },
        CardTop: {
            boxShadow: 'none',
            //boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            border: '0px',
            borderRadius: '0 0 '+borderRadius+'px '+borderRadius+'px',
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            //paddingTop: 0,
            paddingTop: 'calc(10px + env(safe-area-inset-top))', //ios
            //paddingBottom: 10,
            paddingBottom: 8,
            marginBottom: pageDefaultPadding,
            marginTop:0,
            minHeight: 'calc(50px + env(safe-area-inset-top))', //ios
            width: '100%',
            //background : 'linear-gradient(to top, '+palette.themeNaviGrey+' 20%, '+palette.primary.main+' 100%)',
            //background : 'linear-gradient(to top, ' +palette.secondary.light+' 8px, '+palette.themeNaviGrey+' 0px, '+palette.primary.main+' 100% )',
            background : 'linear-gradient(to top, ' +palette.secondary.light+' 8px, '+palette.backPrimary+' 0px, '+palette.backPrimary+' 100% )',
            //background : 'linear-gradient(to bottom, '+palette.themeNaviGrey+' 96%, '+palette.secondary.main+' 100%)',
            //background : 'linear-gradient(to top, '+palette.white+' 20%, '+palette.LightGrey+' 100%)',
            //background : 'linear-gradient(to top, '+palette.themeNaviGrey+' 20%, '+palette.white+' 100%)',
            //backgroundColor: palette.primary.main,
            //opacity: "0.90",
            alignItems: "center",
            justifyContent: "center",
        },
        CardDesktopTop: {
            boxShadow: 'none',
            //boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            border: '0px',
            borderRadius: '0 0 '+borderRadius+'px '+borderRadius+'px',
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            //paddingTop: 0,
            paddingTop: 'calc(10px + env(safe-area-inset-top))', //ios
            //paddingBottom: 10,
            paddingBottom: 8,
            marginBottom: pageDefaultPadding,
            marginTop:0,
            minHeight: 'calc(50px + env(safe-area-inset-top))', //ios
            width: '100%',
            background : 'linear-gradient(to top, ' +palette.secondary.light+' 8px, '+palette.backPaper+' 0px, '+palette.backPaper+' 100% )',
            alignItems: "center",
            justifyContent: "center",
        },
        CardToday: {
            boxShadow: 'none',
            border: '0px',
            borderRadius: borderRadius,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            paddingTop: 0,
            paddingBottom: pageDefaultPadding,
            marginBottom: pageDefaultPadding,
            marginRight: pageDefaultPadding, 
            background : 'linear-gradient(to top, '+palette.primary.dark+' 20%, '+palette.primary.main+' 100%)',
            //backgroundColor: palette.primary.main,
            //opacity: "0.90",
            alignItems: "center",
            justifyContent: "center",
            titleText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '18px',
                letterSpacing: '-0.05px',
                lineHeight: '20px',
                alignItems: "bottom",
            },
        },
        CardBack: {
            boxShadow: 'none',
            border: '0px',
            paddingTop: 0,
            paddingLeft: 0,
            paddingRight: 0,
            paddingBottom: 20,
            marginBottom: 30,
            marginLeft: 0,
            marginRight: 0,
            display: 'flex',
            flexFlow: 'column',
            height: '100%',
            backgroundColor: palette.backPrimary,
            titleText: {
                color: palette.themeText,
                //fontWeight: 700,
                letterSpacing: '-0.8px',
            },
        },
        CardContent: {
            boxShadow: 'none',
            border: '0px',
            paddingTop: 0,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            paddingBottom: 20,
            marginBottom: 30,
            minHeight: 100,
            //backgroundColor: 'transparent',
            backgroundColor: palette.backPrimary,
            titleText: {
                color: palette.themeText,
                //fontWeight: 700,
                letterSpacing: '-0.8px',
            },
        },
        CardContentItem: {
            root: {
                boxShadow: 'none',
                border: 0,
                paddingTop: 0,
                //borderRadius: 0,
                marginBottom: pageDefaultPadding,
                //color: palette.themeGrey,
                backgroundColor: palette.themePaper,
                color: palette.themeText,
                //cursor: "pointer",
            },
            rootComments: {
                boxShadow: 'none',
                border: 0,
                borderRadius : borderRadius,
                padding: 0,
                backgroundColor: palette.white,
                color: palette.themeText,
                //cursor: "pointer",
            },
            rootSwipe: {
                boxShadow: 'none',
                border: 0,
                paddingTop: 0,
                borderRadius: borderRadius,
                //marginBottom: pageDefaultPadding,
                //backgroundColor: palette.backPrimary,
                color: palette.themeText,
                marginBottom: 5,
                //cursor: "pointer",
            },
            rootSwipeShadow: {
                boxShadow: '0 0 0 1px rgb(63 63 68 / 5%), 0 1px 3px 0 rgb(63 63 68 / 15%)',
                paddingTop: 0,
                borderRadius: borderRadius,
                marginTop: 1,
                marginRight: 1,
                marginLeft: 1,
                marginBottom: pageDefaultPadding,
                backgroundColor: palette.backPrimary,
                color: palette.themeText,
                //cursor: "pointer",
            },
            rootFeed: {
                boxShadow: 'none',
                border: 0,
                paddingTop: 0,
                borderRadius: borderRadius,
                marginBottom: pageDefaultPadding,
                paddingBottom: pageDefaultPadding,
                backgroundColor: palette.backPaper,
                color: palette.themeText,
                //cursor: "pointer",
            },
            CardContent: {
                boxShadow: 'none',
                border: '0px',
                borderRadius: borderRadius,
                margin: 10,
                paddingTop: 0,
                paddingBottom: 0,
                paddingLeft: pageDefaultPadding,
                paddingRight: pageDefaultPadding,
                backgroundColor: palette.backPaper,
                top: 0,
                textAlign: "left",
            },
            CardContentSpace: {
                boxShadow: 'none',
                border: '0px',
                width: '100%',
                borderRadius: borderRadius,
                marginLeft: 0,
                marginTop: 0,
                marginBottom: 20,
                paddingTop: 0,
                paddingBottom: 10,
                paddingLeft: pageDefaultPadding,
                paddingRight: pageDefaultPadding,
                backgroundColor: palette.backPaper,
                top: 0,
                textAlign: "left",
            },
            CardContentFeed: {
                boxShadow: 'none',
                border: '0px',
                margin: 0,
                paddingLeft: pageDefaultPadding,
                paddingRight: pageDefaultPadding,
                paddingTop: pageDefaultPadding,
                paddingBottom: pageDefaultPadding,
                //backgroundColor: palette.backPaper,
                textAlign: "left",
            },
            CardContentSwipe: {
                //display: 'inline-flex', 
                verticalAlign: 'middle',
                backgroundColor: palette.backPrimary
            },
            TextTitle: {
                //fontWeight: "bold",
                fontSize: '24px',
                color: palette.themeText,
            },
            TextSubTitle: {
                wordWrap:'break-word',
                fontSize: '16px',
                color: palette.themeTextSecondary,
            },
            CardMedia: {
                paddingTop: "56.25%",// 16:9,
                //paddingTop: "70%",// 16:9,
                backgroundPosition: 'center 30%',
                justifyContent: 'center',
                alignItems: 'center',
                position: "relative",
            },
            CardMediaDesktop: {
                justifyContent: 'center',
                alignItems: 'center',
                position: "relative",
            },
            CardAction: {
                marginLeft: 0,
                marginRight: 0,
                paddingLeft: 0,
                paddingTop: pageDefaultPadding,
                paddingRight: 0,
            },
            ribbon: {
                position: "absolute",
                top: 14,
                right: 0,
                backgroundColor: palette.black,
                color: palette.white,
                paddingTop: 9.5,
                paddingBottom: 9.5,
                paddingLeft: 9.5,
                paddingRight: 11,
                borderTopLeftRadius: borderRadius,
                borderBottomLeftRadius: borderRadius,
                "&:before, &:after": {
                    position: "absolute",
                    right: -16,
                    content: '" "',
                    borderLeft: `16px solid ${palette.secondary.main}`
                },
                "&:before": {
                    top: 0,
                    borderBottom: "12px solid transparent"
                },
                "&:after": {
                    bottom: 0,
                    borderTop: "12px solid transparent"
                },
                Typography: {
                    fontWeight: "bold",
                    fontSize: '16px',
                    color: palette.white,
                }
            },
            ribbonSkill: {
                position: "absolute",
                top: 14,
                right: 10,
                backgroundColor: 'transparent',
                padding: 0,
                margin: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            ribbonIcon: {
                position: "absolute",
                paddingTop: 5.5,
                paddingBottom: 3,
                paddingLeft: 9.5,
                paddingRight: 11,
                top: 14,
                left: 0,
                backgroundColor: palette.black,
                color: palette.white,
                borderTopRightRadius: borderRadius,
                borderBottomRightRadius: borderRadius,
            },
        },
        CardDialogueButton: {
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.12)',
            //boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            borderRadius: borderRadius,
            alignItems: "center",
            //height: '19.14130434vw',
            minWidth: 240,
            minHeight: "8vh",
            //maxHeight: "14vh",
            backgroundColor: (palette.backPrimary+'AA'),
            //opacity: "1.00",
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 10,
            marginBottom: 10,
            overflow: 'hidden',
            //fontSize: 'calc(1.4vw + 2.0vh)',
            //fontSize: '4.0vw',
            //letterSpacing: '0.53',
            button: {
                color: palette.primary.dark,
            },
        },
        CardDialogueButtonDesktop: {
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.12)',
            borderRadius: borderRadius,
            alignItems: "center",
            //height: 100,
            minHeight: 100,
            backgroundColor:  (palette.backPrimary+'AA'),
            paddingLeft: 0,
            paddingRight: 10,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 10,
            marginBottom: 10,
            overflow: 'hidden',
            //letterSpacing: '0.53',
            button: {
                color: palette.primary.dark,
            },
        },
        CardDialogueButtonTransparent: {
            boxShadow: 'none',
            borderRadius: borderRadius,
            alignItems: "center",
            //minHeight: "8vh",
            width:"100%",
            //height: "8vh",
            minWidth: 240,
            minHeight: "8vh",
            //maxHeight: "14vh",
            backgroundColor: 'transparent',
            paddingLeft: 0,
            paddingRight: 0,
            paddingTop: 0,
            paddingBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            marginTop: 10,
            marginBottom: 10,
            overflow: 'hidden',
            //letterSpacing: '0.53',
            button: {
                color: palette.primary.dark,
            },
        },
        CardDialogueButtonFrame: {
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.12)',
            borderRadius: borderRadius,
            alignItems: "center",
            backgroundColor: palette.primary.main,
            marginBottom: 10,
        },
        CardContentShadow: {
            boxShadow: '0 0 20px 0 rgba(0,0,0,0.12)',
            border: '0px',
            backgroundColor: palette.backPrimary,
            borderRadius: borderRadius,
            padding: 10
        },
        CardGoals: {
            //boxShadow: "0 16px 40px -12.125px rgba(0,0,0,0.3)",
            border: '0px',
            borderRadius: borderRadius,
            padding: 14,
            marginBottom: 10,
            backgroundColor: (palette.backPrimary+"AA"),
            opacity:'1.0',
            overflowY: 'auto',
            titleText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '18px',
                letterSpacing: '-0.05px',
                lineHeight: '18px',
            },
            mainText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '14px',
                lineHeight: '14px',
            },
            chooseTodayText: {
                color: palette.themeText,
                fontWeight: 300,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                lineHeight: '16px',
                alignItems: "bottom",
            },
        },
        CardJournal: {
            border: '0px solid white',
            boxShadow: 'none',
            paddingTop: 10,
            paddingLeft: pageDefaultPadding,
            paddingRight: 0,
            paddingBottom: 10,
            marginBottom: 50,
            marginLeft: 0,
            marginRight: 0,
            minHeight: 100,
            backgroundColor: 'transparent',
            titleText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '28px',
                letterSpacing: '-0.05px',
                lineHeight: '20px'
            }
        },
        cardCommentsOthers: {
            backgroundColor: 'transparent',
            //marginLeft: 20,
            minHeight: 120,
            padding: 10,
            boxShadow: 'none',
            border: 0,
            borderRadius: 0,
            marginBottom: 15,
        },
        cardJournalDayHighlight: {
            backgroundColor: palette.backPrimary,
            //background : 'linear-gradient(0deg, '+palette.primary.light+' 0%, '+palette.primary.main+' 50%)',
            borderRadius: borderRadius,
            margin: 2,
            marginBottom: 10,
            minHeight: 80,
            padding: 0,
            iconDesign: {
                color: 'darkgrey',
                fontSize: 12,
            },
            titleText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '16px'
            },
            CardMedia: {
                //paddingTop: "56.25%",// 16:9,
                paddingTop: 56,// 16:9,
                justifyContent: 'center',
                alignItems: 'center',
                position: "relative",
            },
            ribbonTitle: {
                position: "absolute",
                top: 8,
                margin: 0,
                marginBottom: 8,
                left: 20,
                color: palette.secondary.main,
                backgroundColor: 'transparent',
                padding: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
            ribbonSkill: {
                position: "absolute",
                top: 8,
                margin: 0,
                marginBottom: 8,
                right: 10,
                backgroundColor: 'transparent',
                padding: 0,
                borderTopLeftRadius: 0,
                borderBottomLeftRadius: 0,
            },
        },
        cardGridJournalDayHighlight: {
            display: "flex",
            alignItems: "top",
            justifyContent: "center",
            paddingBottom: 10,
        },
        cardGridDate: {
            display: "flex",
            color: palette.secondary.default,
            alignItems: "top",
            justifyContent: "center"
        },
        cardSettings: {
            backgroundColor: 'transparent',
            margin: 0,
            minHeight: 80,
            minWidth: 240,
            overflow: 'none',
            paddingBottom: 10,
            color: palette.themeText,
            //paddings moved to de
            //paddingLeft: pageDefaultPadding,
            //paddingRight: pageDefaultPadding,
            border: 0,
            boxShadow: 'none',
            iconDesign: {
                color: palette.themeGrey,
                maxWidth: 40,
                //height: 20,
                //marginRight: 10,
            },
        },
        cardAdminSettings: {
            backgroundColor: palette.darkMode ? palette.themeDarkBack: palette.backPaperSecondary,
            border: "1px solid "+palette.backPaperSecondary,
            borderRadius: (borderRadius || 12),
            margin: 5,
            minHeight: 100,
            minWidth: 260,
            overflow: 'none',
            padding: 20,
            boxShadow: 'none',
            cursor: 'pointer'
        },
        cardWordCloud: {
            background : 'linear-gradient(0deg, '+palette.primary.dark+' 0%, '+palette.primary.main+' 50%)',
            margin: 0,
            minHeight: 200,
            minWidth: 200,
            padding: 0,
            borderRadius: borderRadius, 
            iconDesign: {
                color: 'darkgrey',
                fontSize: 12,
            },
            titleText: {
                color: palette.themeText,
                fontWeight: 500,
                fontSize: '14px',
                letterSpacing: '-0.05px',
                lineHeight: '16px'
            },
            tagCloud: {
                fontSize: 30,
                tagsPrimaryColor: palette.secondary.light,
                tagsSecondaryColor: palette.secondary.main,
                tagsThirdColor: palette.secondary.dark,
            }
        },
        cardBorder: {
            marginTop: 20, 
            marginBottom: 20, 
            borderStyle: 'solid', 
            borderWidth: 'thin', 
            borderRadius: borderRadius, 
            marginLeft:0, 
            padding: 20,
            borderColor: palette.secondary.light, 
            backgroundColor: palette.backPaper, 
            width:"100%"
        },
    
        //////// TEXT
        textTodayDate: {
            color: palette.themeGrey,
            fontSize: '2.03804vh',
            fontWeight: 'bold',
            letterSpacing: '0.8px',
            textTransform: 'uppercase',
            marginBottom: '-1.309782vh',
        },
        textSupportRight: {
            color: palette.secondary.themeGrey,
            fontSize: 12,
            textAlign: 'right',
        },
        textMonthCenter: {
            color: palette.secondary.themeGrey,
            fontSize: 18,
            textAlign: 'center',
        },
        textCenterPadding : {
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            textAlign: 'center',
        },
        textCenter : {
            textAlign: 'center',
        },
        textErrorCenterPadding : {
            marginTop: 20,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            color: palette.themeRed,
            textAlign: 'center',
        },
        textSettings: {
            color: palette.themeText,
            fontWeight: 500,
            minHeight: 40,
            fontSize: '14px',
            letterSpacing: '-0.05px',
            lineHeight: '16px',
            verticalAlign: 'top',
            settingsTextDescription: {
                maxWidth: '60%'
            },
            settingsTextValue: {
                color: palette.black,
                float: 'right',
                clear: 'inline-end',
                fontWeight: 700,
                textAlign: 'right',
                background: palette.white+'AA', 
                width: '50%',
                padding: 8, 
                marginBottom: 4,
                marginTop: 4,
                borderRadius: borderRadius
            },
            settingsFixedTextValue: {
                color: palette.secondary.main,
                display: 'flex',
                float: 'right',
                clear: 'inline-end',
                fontWeight: 700,
                paddingTop: 8,
                paddingBottom: 8,
                paddingLeft:8 
            },
        },
        textSupport: BackgroundTextSupportColor({
            color: palette.secondary.light,
            marginTop: 10,
            marginBottom: 10,
        }),
        textSupportTeal: {
            color: palette.secondary.light,
            marginTop: 10,
            marginBottom: 10,
        },
        textSupportGrey: {
            color: palette.themeGrey,
            marginTop: 10,
            marginBottom: 10,
        },
        textSupportCenterPadding : BackgroundTextSupportColor({
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            color: palette.secondary.light,
            marginTop: 10,
            marginBottom: 10,
            textAlign: 'center',    
        }),
        textSupportCenter : BackgroundTextSupportColor({
            paddingLeft: 10,
            paddingRight: 10,
            display: 'block',
            width: '100%',
            color: palette.secondary.light,
            textAlign: 'center',
            marginTop: 10,
            marginBottom: 10,
        }),
        textSupportAction : BackgroundTextSupportColor({
            paddingLeft: 3,
            paddingRight: 3,
            color: palette.secondary.light,
            textAlign: 'center',
        }),
        textTitleText: {
            color: palette.themeText,
            fontWeight: 500,
        },
        textTitleNoWrap: {
            color: palette.themeText,
            fontWeight: 500,
            whiteSpace: 'nowrap'
        },
        textBigTitleText: {
            color: palette.themeText,
            fontWeight: 500,
            fontSize: '18px',
            letterSpacing: '-0.05px',
            lineHeight: '20px',
            marginTop: 5,
        },
        textInputMultiline: {
            color: 'green',
            marginTop: 40,
            marginBottom: 30,
            width: '100%',
            fontSize: '16px',
        },
        inlineIconH2: {
            fontSize: 'inherit', 
            marginRight: 4, 
            position: 'relative', 
            bottom: -3
        },
        inlineIconLeft: {
            fontSize: 'inherit', 
            marginRight: 4, 
            position: 'relative', 
            bottom: -2
        },
        inlineIconRight: {
            fontSize: 'inherit', 
            marginLeft: 4, 
            position: 'relative', 
            bottom: -2
        },
        ////// DIALOG Context
        dialogWindow:{
            maxWidth: 600,
            minWidth: '50vw',
            //marginTop: "5%",
            //marginBottom: '5%',
            //height:"90%",
            paddingTop: 'calc(0px + env(safe-area-inset-top))', //ios,
            //marginTop: 'calc(0px + env(safe-area-inset-top))', //ios,
            marginBottom: 'calc(0px + env(safe-area-inset-bottom))', //ios,
            paddingBottom: 'calc(px + env(safe-area-inset-bottom))', //ios,
            height: "100%",
            //height: 'calc(100% - env(safe-area-inset-top) - env(safe-area-inset-bottom))',
            borderRadius: borderRadius,
            overflow: "hidden",
            //borderTopLeftRadius:borderRadius, 
            //borderTopRightRadius:borderRadius, 
        },
        dialogPopUp:{
            minWidth: 250,
            marginTop: "5%",
            borderRadius:borderRadius,
            minHeight: 250,
            maxHeight: '70vh',
            overflow: "hidden",
            marginBottom:'5%',
        },
        dialogContent: {
            marginTop: 0,
            paddingTop: pageDefaultPadding,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            paddingBottom: 100,
            overflowX: 'hidden',
            //backgroundColor: palette.white,
        },
        dialogContentMain: {
            paddingTop: pageDefaultPadding,
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            paddingBottom: 100,
            overflowX: 'hidden',
            //background : 'linear-gradient(to top, '+palette.primary.light+' 40%, '+palette.white+' 100%)',
            backgroundColor: palette.backPrimaryPaper,
        },
        dialogImage: {
            width: '100%',
            marginRight: 0,
            marginLeft: 0,
            paddingRight: 0,
            paddingLeft: 0,
            //paddingTop: "56.25%",// 16:9,
        },
        dialogTitle: {
            //background : 'linear-gradient(to top, '+palette.backPrimary+' 20%, '+palette.backPaper+' 100%)',
            background : palette.backPaper,
            color: palette.themeText,
            borderRadius: '0 0 '+borderRadius+'px '+borderRadius+'px',
            fontSize: 13,
            minHeight: 55,
            paddingTop:5,
            paddingBottom:0,
            alignItems:'center',
            justifyContent:'center',
            paddingLeft: 0,
            paddingRight: 12,
            marginLeft: 0,
            titleText: {
                fontWeight: 700,
                color: palette.themeText,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                alignItems: "bottom",
            },
            titleLink: {
                color: palette.secondary.main,
                //fontWeight: 700,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                alignItems: "bottom",
                textAlign: "right",
            },
            infoItem: {
                position: 'relative',
                right: 0, 
                float: 'right', 
                top: 'calc(12px + env(safe-area-inset-top))', //ios
            },
        },
        dialogTitleWhite: {
            backgroundColor: palette.white,
            fontSize: 13,
            paddingTop:0,
            paddingBottom:0,
            alignItems:'center',
            justifyContent:'center',
            paddingLeft: 0,
            marginLeft: 0,
            height: 60,
            //maxWidth: maxWidthDialog,
            //width: "100%",
            //zIndex: 1500,
            boxShadow: '0 2px 4px 0 rgba(0,0,0,0.08)',
            titleText: {
                color: palette.themeText,
                fontWeight: 700,
                fontSize: '16px',
                letterSpacing: '-0.05px',
                alignItems: "bottom",
            },
        },
        dialogAction: {
            marginTop: 0,
            overflowX: 'hidden',
            //background: 'transparent',
            backgroundColor: palette.secondary.main,
            borderRadius: ''+borderRadius+'px '+borderRadius+'px 0 0',
            //height: 65, /// ios
            //paddingBottom: 15, /// ios
        },
    
        /////// div alignments
        divDefaultSidePadding: {
            paddingLeft: pageDefaultPadding,
            paddingRight: pageDefaultPadding,
            paddingBottom: pageDefaultPadding,
        },
        divSpaceVertical: {
            paddingTop: pageDefaultPadding,
            paddingBottom: pageDefaultPadding,
        },
        divCenter: {
            textAlign: 'center',
            overflowX: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            position: "relative",
        },
        divCenterPadding: {
            textAlign: 'center',
            overflowX: 'hidden',
            justifyContent: 'center',
            alignItems: 'center',
            position: "relative",
            paddingTop: pageDefaultPadding,
            paddingBottom: pageDefaultPadding,
        },
        divFullWidth: {
            width: '100%',
        },
    
        ///// dangeriouslyInner HTML
    
        innerHTML : {
            width: '100%!important', 
            maxWidth: '100%!important', 
            height: 'auto!important', 
            padding: 0,
            '& p': {
                //width: '100%!important', 
                maxWidth: '100%!important', 
                height: 'auto!important', 
            },
            "& img": {
                //width: '100%!important', 
                maxWidth: '100%!important', 
                height: 'auto!important', 
                borderRadius: borderRadius
            },
            "& span": {
                //width: '100%!important', 
                maxWidth: '100%!important', 
                height: 'auto!important', 
            },
            "& h1": {
                maxWidth: '100%!important', 
                height: 'auto!important', 
            },
            "& div": {
                maxWidth: '100%!important', 
                height: 'auto!important', 
            },
            "& table": {
                maxWidth: '100%!important', 
                //width: '100%!important',
                height: 'auto!important',
                borderSpacing: 0,
                borderCollapse: 'collapse', 
                border: '1px solid black',
            },
            "& table tr": {
                border: '1px solid black',
                borderSpacing: 0,
            },
            "& table td": {
                border: '1px solid black',
                borderSpacing: 0,
                padding: 6,
            },
        },
    
        ///// PageLayouts
        HomeContentDesktop: {
            paddingTop: 5,
        },
        HomeContentRoot: {
            //backgroundColor: palette.secondary.light,
            //background : 'linear-gradient(to bottom, '+palette.primary.dark+' 0%, '+palette.primary.main+' 100%)',
            flexGrow: 1,
        },
        HomeNavigationShiftContent: {
            paddingLeft: 0, //pageDefaultPadding,
            paddingTop: 40,
            //paddingRight: pageDefaultPadding,
            //paddingLeft: pageDefaultPadding,
            paddingBottom: pageDefaultPadding,
            position: "sticky",
        },
        HomeNoNavigationShiftContent: {
            paddingLeft: 0,
            paddingTop: 0,
            // paddingTop: '7.6vh',
            paddingRight: 0,
            paddingBottom: 60,
        },
        WelcomeLogin: {
            //// V2.0.0
            //backgroundImage: 'linear-gradient(230deg, '+palette.themeAltLightGrey+'77 0%, /* Transparent for top third */ '+palette.themeAltLightGrey+'98 66%, /* Semi-opaque color for middle third */ '+palette.themeAltLightGrey+'99 100% /* Solid color for bottom third */)',
            //// V2.0.3
            backgroundImage: 'linear-gradient(230deg, '+palette.themeAltLightGrey+'C2 0%, /* Transparent for top third */ '+palette.themeAltLightGrey+'B2 66%, /* Semi-opaque color for middle third */ '+palette.themeAltLightGrey+' 100% /* Solid color for bottom third */)',
            //opacity: "0.9",
            display: 'grid',
            alignContent: 'space-between',
            //background: "rgba(255,255,255,.7)",
            minHeight: "calc( 100vh - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
            //marginTop: 'calc( 20px + env(safe-area-inset-top))',
            marginBottom: 'calc( 0px + env(safe-area-inset-bottom))',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 10,
            paddingRight: 10,
            //padding: 10,
            width: "calc( 100vw - 20px)",
            maxWidth: maxWidthDialog,
            marginLeft: 10,
            marginRight: 10,
            borderRadius: borderRadius
        },
        WelcomeLoginDT: {
            //backgroundColor: palette.themeLightGrey+"E6",
            //backgroundColor: palette.themeAltLightGrey,
            ///// V2.0.0
            //backgroundImage: 'linear-gradient(230deg, '+palette.themeAltLightGrey+'77 0%, /* Transparent for top third */ '+palette.themeAltLightGrey+' 66%, /* Semi-opaque color for middle third */ '+palette.themeAltLightGrey+' 100% /* Solid color for bottom third */)',
            //// V2.0.3
            backgroundImage: 'linear-gradient(230deg, '+palette.themeAltLightGrey+'C2 0%, /* Transparent for top third */ '+palette.themeAltLightGrey+' 66%, /* Semi-opaque color for middle third */ '+palette.themeAltLightGrey+' 100% /* Solid color for bottom third */)',
            color: palette.themeText,
            //opacity: "0.6",
            display: 'grid',
            alignContent: 'space-between',
            //background: "rgba(255,255,255,.7)",
            minHeight: "calc( 100vh - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
            //marginTop: 'calc( 20px + env(safe-area-inset-top))',
            marginBottom: 'calc( 0px + env(safe-area-inset-bottom))',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 20,
            paddingRight: 20,
            //padding: 10,
            width: "auto",
            maxWidth: maxWidthDialog,
            //minWidth: 300,
            marginLeft: 10,
            marginRight: 10,
            borderRadius: borderRadius
        },
        WelcomeLoginInverseDT: {
            backgroundImage: 'linear-gradient(162deg, ' + palette.themeAltLightGrey+'5C 0%, ' +palette.themeAltDarkBlue+'BB 25%, /* Transparent for top third */ '+palette.themeAltDarkBlue+'59 80%, /* Semi-opaque color for middle third */ '+palette.themeAltLightGrey+'33 100% /* Solid color for bottom third */)',
            color: palette.themeTextInv,
            //opacity: "0.6",
            display: 'grid',
            alignContent: 'space-between',
            //background: "rgba(255,255,255,.7)",
            minHeight: "calc( 100vh - 60px - env(safe-area-inset-top) - env(safe-area-inset-bottom))",
            //marginTop: 'calc( 20px + env(safe-area-inset-top))',
            marginBottom: 'calc( 20px + env(safe-area-inset-bottom))',
            paddingTop: 20,
            paddingBottom: 20,
            paddingLeft: 60,
            paddingRight: 60,
            //padding: 10,
            minWidth: "40vw",
            maxWidth: maxWidthDialog,
            marginLeft: 10,
            marginRight: 10,
            borderRadius: borderRadius
        },
        WelcomeDesktop: BackgroundMode({
            backgroundColor: palette.primary.main,
            opacity: "0.6",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            borderRadius: borderRadius,
            maxWidth: maxWidthDialog,
            width: '100%',
        }),
        WelcomeMobile: BackgroundMode({
            backgroundColor: palette.primary.main,
            opacity: "1.00",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            borderRadius: 0,
            maxWidth: maxWidthDialog,
            width: '100%' 
        }),
        OnboardDesktop: {
            backgroundColor: palette.backPaper,
            opacity: "0.96",
            marginTop: "2%",
            marginBottom: "2%",
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            borderRadius: borderRadius,
            maxWidth: maxWidthDialog,
            width: '100%',
        },
        OnboardMobile: {
            backgroundColor: palette.backPaper,
            opacity: "1.00",
            marginTop: 0,
            marginBottom: 0,
            marginLeft: 0,
            marginRight: 0,
            padding: 0,
            borderRadius: 0,
            maxWidth: maxWidthDialog,
            width: '100%' 
        },
    
        ////// Gernal
        AccordionhideBorder: {
            '&.MuiAccordion-root:before' : {
                display: 'none',
            },
            '&.MuiPaper-root' : {
                backgroundColor: 'transparent',
            },
            boxShadow: 'none',
        },
        highlightTab: {
            position: "fixed",
            top: 0,
            backgroundColor: palette.white,
            zIndex: 1002
        },
        loading: {
            color: palette.secondary.dark,
            marginTop: 100,
            textAlign: 'center',
            size: 40,
            top: 100,
            marginLeft: '45%'
        },
        loadingCenterPadding: {
            textAlign: 'center',
            minHeight: '40vh'
        },
        signUpIn: {
            signUpInButton: {
                border: "1px solid white",
                //borderRadius: 5,
                // borderRadius: 5, //use theme
                margin: 20,
                paddingLeft: 10,
                paddingRight: 10,
                paddingTop: 5,
                paddingBottom: 5,
                color: "white"
                }
        },
        logo: {
            width: "20%",
            maxWidth: 180,
            marginTop: 10,
            marginBottom: 10,
            borderRadius: borderRadius
        },
        logoCaption: {
            margin: 10,
            width: "40%"
        },
        logoContainer: {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center"
        },
        hideExpansionPanelBorder: {
            '&.MuiAccordion-root:before' : {
                display: 'none',
            },
            boxShadow: 'none',
        },
    })
}

export const theme = createTheme(modifiedStyles(palette));
export const themeDark = createTheme(modifiedStyles(paletteDark));
export function ReloadTheme(defaultSet = {}) {
    //console.log('modified string', defaultSet)
    const newPalette = UpdatedPalette(defaultSet);
    //console.log('newPalette', newPalette)
    return createTheme(modifiedStyles(newPalette));
}

export default theme;